.item-wrapper {
    display: flex;
    flex-direction: row;
}

.sub-item-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-width: 20rem;
    padding: 0.75rem;
}

.product-sub-item {
    width: 8rem;
    padding: 0.5rem;
    font-size: 0.9rem;
}

.productKitContent {
    display: flex;
    flex-direction: column;
    text-align: -webkit-auto;
    width: 20rem;
    max-width: 100vw;
    padding: 1rem;
}