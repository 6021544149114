.nav-icon {
    cursor: pointer;

    margin-right: 0.5rem;
    margin-top: 0.5rem;
    height: 1.3rem;
    object-fit: scale-down;
    mix-blend-mode: darken;
}

.footer-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #18A2A8;
    padding: 0.5rem;
    min-width: 80%;
}

.footer-header {
    width: -webkit-fill-available;

    margin: 0.5rem;
    padding: 0.5rem;

    color: white;
    font-size: 1.25rem;
    border-bottom: 1px solid white;
}

.footer-link {
    margin: 0.5rem;

    color: white;
    font-size: 1rem;
    text-decoration: none;
}