.section-resource {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    padding-top: 2rem;
    padding-bottom: 2rem;
}

.video-resource {
    height: 10rem;
    width: 20rem;

    max-width: 80vw;
    max-height: 40vw;

    margin: 1rem;

    border: none;
}