.map-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.map-wrapper.margin-bottom {
    margin-bottom: 2rem;
}

.map-location-wrapper {
    display: flex;
    flex-direction: column;
    padding: 1rem;

    height: 27rem;
    max-width: -webkit-fill-available;
}

.margin-auto {
    margin: auto;
}

.map-location {
    width: 30rem;
    height: 20rem;
    margin-bottom: 1rem;
    max-width: 90vw;
    max-height: 60vw;
    background-color: lightgrey;
}

.map-location-text-wrapper {
    display: flex;
    flex-direction: column;
    width: 30rem;
    max-width: -webkit-fill-available;
}

.map-location-text {
    display: flex;
    flex-direction: row;
    text-align: -webkit-auto;
    margin-bottom: 0.25rem;
}

.qr-code {
    width: 20rem;
    max-width: 100%;
    object-fit: scale-down;
}

@media screen and (max-width: 70rem) {
    .map-wrapper {
        flex-direction: column;
    }

    .map-location-wrapper {
        height: max-content;
    }
}