.technical-option-wrapper {
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;

    width: 20rem;
    height: 6rem;

    max-width: 60vw;
    max-height: 18vw;

    padding: 2rem;
    margin: 1rem;

    background-color: #F3F2F2;
    color: black;

    transition: 0.2s;
    text-decoration: none;
}

.technical-option-wrapper:hover {
    background-color: #18A2A8;
    color: white;
}

.technical-option-text {
    width: 50%;

    text-align: -webkit-auto;
    font-size: 1.25rem;

    text-decoration: none;
}

.technical-option-image {
    object-fit: scale-down;
    height: 8rem;
    max-height: 100%;

    background-color: white;
}