.tables-wrapper {
    width: 100%;
    overflow-x: scroll;
}

.table-wrapper {
    padding: 1rem;
}

.table-title {
    text-align: left;
    font-weight: bold;
    margin-bottom: 1rem;
}

.table-row {
    width: -webkit-fill-available;
}

.table-cell {
    padding: 0.5rem;
    text-align: left;
}