.page-header-nav {
    text-align: -webkit-auto;
    padding: 1rem;
}

.about-wrapper-outer {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 2rem;
    padding-bottom: 2rem;
}

.about-content-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 70rem;
    width: 100%;
}

.about-section-wrapper {
    display: flex;
    flex-direction: row;

    padding: 0rem;
    text-align: -webkit-auto;
    font-size: 1.2rem;
}

.about-section-wrapper.left {
    flex-direction: column;
    width: 60%;
}

.about-section-wrapper.right {
    flex-direction: column;
    width: 40%;
}

.about-section-wrapper.inner {
    padding: 2rem;
}

.about-section-image {
    object-fit: cover;
    padding: 1rem;
    height: 100%;
    border-radius: 2em;
}

@media screen and (max-width: 800px) {
    .about-section-wrapper {
        flex-direction: column-reverse;
    }

    .about-section-wrapper.left,
    .about-section-wrapper.right {
        width: -webkit-fill-available;
    }

    .about-section-wrapper.right {
        padding: 0;
    }

    .about-section-wrapper.inner {
        padding-bottom: 0;
    }
}